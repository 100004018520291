import * as React from "react"
import Layout from "../components/layout";
import Emoji from "../components/emoji";
import {useState} from "react";
import {isValidEmail} from "../utils/validation";
import Modal from "../components/modal";

// markup
const ContactPage = () => {

  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  })

  const [modal, setModal] = useState({
    hidden: true
  });

  const handleSubmit = (event) => {
    fetch(`${process.env.GATSBY_API_ENDPOINT}/public/feedback/website`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "X-API-VERSION": "1" },
      body: JSON.stringify({
        email: form.email,
        firstName: form.name,
        message: form.message
      })
    })
      .then(response => {
        if (response.ok) {
          return true;
        } else {
          throw response.statusText;
        }
      })
      .then(() => {
        setModal({
          hidden: false,
          title: "Thanks for the feedback 😍️",
          message: "We are always looking to improve the app. So this helps us a lot."
        });
        setForm({
          name: '',
          email: '',
          message: ''
        });
      })
      .catch(err => setModal({
        hidden: false,
        title: 'Something went wrong 🙈',
        message: "Please try again."
      }))
    event.preventDefault();
  }

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value }
    );
  }

  const isFormValid = () => {
    return isValidEmail(form.email) && form.name && form.message;
  }

  const handleDismiss = () => {
    setModal({
      ...modal,
      hidden: true
    });
  }

  return (
    <>
      <Layout title="Contact">
        <div className="container py-20 text-center">
          <h1 className="text-5xl mb-3">We would love to hear from you <Emoji emoji="🤗" label="hugging face"/></h1>
          <p className="text-xl mb-8">
            Whether you have questions, suggestions, or just want to say hi, drop us a line.
          </p>
          <form onSubmit={handleSubmit}
                className="flex flex-col mx-auto space-y-3 text-xl max-w-4xl">
            <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3">
              <input type="text"
                     name="name"
                     value={form.name}
                     onChange={handleChange}
                     className="px-6 py-3 rounded-lg flex-auto"
                     maxLength="35"
                     placeholder="Your name"/>
              <input type="email"
                     name="email"
                     value={form.email}
                     onChange={handleChange}
                     className="px-6 py-3 rounded-lg flex-auto"
                     maxLength="35"
                     placeholder="Email"/>
            </div>
            <textarea name="message"
                      required
                      value={form.message}
                      onChange={handleChange}
                      className="px-6 py-3 rounded-lg mb-3"
                      maxLength="1000" placeholder="Enter your message here..." rows="5"/>
            <button type="submit"
                    disabled={!isFormValid()}
                    className="self-stretch sm:self-end">
              Submit
            </button>
          </form>
        </div>
      </Layout>
      <Modal hidden={modal.hidden} onDismiss={handleDismiss}>
        <h1 className="text-4xl">{modal.title}</h1>
        <p className="text-xl">{modal.message}</p>
      </Modal>
    </>
  )
}

export default ContactPage
